





































import Vue from 'vue';
import { mapMutations } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default Vue.extend({
  name: 'OrdersImport',
  data() {
    return {
      file: null,
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    TheFabButton: () => import('@/components/ui/TheFabButton.vue'),
  },
  methods: {
    ...mapMutations('globals', ['SET_FILE']),
    nextView(): void {
      this.$router.push({ name: 'ImportedOrder' });
    },
    focusOut(): void {
      this.$refs.fileInput.blur();
    },
  },
  watch: {
    file: {
      handler(value: File | null): void {
        this.SET_FILE(value);
      },
    },
  },
  created() {
    const { icons, actions } = this.$headerService.getIconsAndActions();
    this.$headerService.setHeaderIcons({ icon: icons.processes, action: actions.processes });
  },
});
