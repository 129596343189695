var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height"},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","max-width":"400","sm":"6"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.nextView)}}},[_c('ValidationProvider',{attrs:{"rules":"ext:csv|required|fileValidation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{ref:"fileInput",attrs:{"accept":".csv","error-messages":errors,"label":_vm.$t('inputs.select-file'),"prepend-icon":'$paperclip'},on:{"change":_vm.focusOut},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [(message.split('\n').length > 1)?_c('ul',_vm._l((message.split('\n')),function(item){return _c('li',{key:item},[_vm._v(_vm._s(item))])}),0):_c('span',[_vm._v(_vm._s(message))])]}}],null,true),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})]}}],null,true)}),_c('TheFabButton',{attrs:{"title":_vm.$t('inputs.confirm'),"icon":"columns","disabled":invalid,"type":"submit"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }